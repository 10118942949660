<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="provider"
        v-model="provider"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="providers"
        :placeholder="t('Chọn nhà cung cấp')"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true && $can('create', 'provider')"
          #list-header
        >
          <li
            v-b-modal.modal-provider
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Thêm nhà cung cấp') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template>
      </v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <!-- modal -->
    <provider-handler
      v-if="enableAdd === true"
      :item="{}"
      @refetch-providers="fetchData"
      @on-item-created="onItemCreated"
    />
  </div>

</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import ProviderHandler from '../inventory/provider/handler/ProviderHandler.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    ProviderHandler,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      provider: this.defaultValue,
      providers: [],
    };
  },
  created() {
    // this.fetchData();
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.provider);
    },
    fetchData() {
      useJwt.getProviders().then(res => {
        this.providers = res.data.items;
      });
    },
    onOpen() {
      if (this.providers.length === 0) {
        this.fetchData();
      }
    },
    onItemCreated(newItem) {
      this.provider = newItem;
      this.valueChanged();
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
