<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="type"
        v-model="type"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="types"
        :placeholder="t('Loại tài sản')"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true && $can('create', 'asset-type')"
          #list-header
        >
          <li
            v-b-modal.modal-asset-type
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Thêm loại tài sản') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <!-- modal -->
    <asset-type-handler
      v-if="enableAdd === true"
      :item="{}"
      @refetch-assetTypes="fetchAssetTypes"
      @on-item-created="onItemCreated"
    />
  </div>

</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import AssetTypeHandler from '../inventory/asset-type/handler/AssetTypeHandler.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    AssetTypeHandler,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: this.defaultValue,
      types: [],
    };
  },
  created() {
    // this.fetchAssetTypes();
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.type);
    },
    fetchAssetTypes() {
      useJwt.getAssetTypes({ sort: { type: 'asc', field: 'slug' } }).then(res => {
        this.types = res.data.items;
      });
    },
    onOpen() {
      if (this.types.length === 0) {
        this.fetchAssetTypes();
      }
    },
    onItemCreated(newItem) {
      this.type = newItem;
      this.valueChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
